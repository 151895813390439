﻿(function () {
    window.QuillFunctions = {
        createQuill: function (
            quillElement, toolBar, readOnly,
            placeholder, theme, debugLevel) {

            var options = {
                debug: debugLevel,
                modules: {
                    toolbar: readOnly ? null : toolBar
                },
                placeholder: placeholder,
                readOnly: readOnly,
                theme: theme
            };

            new Quill(quillElement, options);
        },
        getQuillContent: function (quillElement) {
            return JSON.stringify(quillElement.__quill.getContents());
        },
        getQuillText: function (quillElement) {
            return quillElement.__quill.getText();
        },
        getQuillHTML: function (quillElement) {
            return quillElement.__quill.root.innerHTML;
        },
        loadQuillContent: function (quillElement, quillContent) {
            content = JSON.parse(quillContent);
            return quillElement.__quill.setContents(content, 'api');
        },
        enableQuillEditor: function (quillElement, mode) {
            quillElement.__quill.enable(mode);
        },
        GetQuillHTMLfromDelta: function (delta) {
            var editor = document.createElement("div");
            var quill = new Quill(editor);
            var json = JSON.parse(delta)
            quill.setContents(json.ops, 'api');
            var html = editor.getElementsByClassName("ql-editor")[0].innerHTML;
            return html;
        },
        GetQuillTextfromDelta: function (delta) {
            var editor = document.createElement("div");
            var quill = new Quill(editor);
            var json = JSON.parse(delta)
            quill.setContents(json.ops, 'api');
            var text = quill.getText();     
            return text;
        },
        registerQuillEvent: function (quillElement, dotnetHelper, eventName) {
            if (!quillElement[`__quill_event_${eventName}`]) {
                quillElement[`__quill_event_${eventName}`] = function () {
                    dotnetHelper.invokeMethodAsync("QuillEventCallbackCaller", eventName, [...arguments])
                }
                quillElement.__quill.on(eventName, quillElement[`__quill_event_${eventName}`]);
            }
        },
        unregisterQuillEvent: function (quillElement, eventName) {
            if (quillElement[`__quill_event_${eventName}`]) {
                quillElement.__quill.off(eventName, quillElement[`__quill_event_${eventName}`]);
            }
        }
    };
})();